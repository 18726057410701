const userInputsStructure = {
    id: {
        label: 'Id',
        type: 'text',
        placeholder: 'Enter id',
        validation: {
            presence: true,
            format: {
                pattern: '^[-_.@a-zA-Z0-9]+$',
                message: '^Allowed characters are a-z, 0-9, _@.-'
            },
            length: {
                maximum: 128,
                tooLong: '^Id can not be longer than 128 characters'
            }
        }
    },
    first_name: {
        label: 'First name',
        type: 'text',
        placeholder: 'Enter first name',
        validation: {
            length: {
                maximum: 250,
                tooLong: '^First name can not be longer than 250 characters'
            }
        }
    },
    last_name: {
        label: 'Last name',
        type: 'text',
        placeholder: 'Enter last name',
        validation: {
            length: {
                maximum: 250,
                tooLong: '^Last name can not be longer than 250 characters'
            }
        }
    }
};

export default userInputsStructure;